import React, { useEffect } from 'react';
import Confirm from '../PalmSchool/pages/Confirm';

const VerifyReset = () => {
  const phoneNo = localStorage.getItem('resetEmailOrPhone');
  console.log(phoneNo);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <Confirm phoneNo={phoneNo} reset />;
};

export default VerifyReset;
