/* eslint-disable no-unreachable */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdKeyboardBackspace } from 'react-icons/md';
import 'aos/dist/aos.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion } from 'framer-motion';
import styles from './styles/FindAccount.module.scss';
import Loader from '../PalmSchool/components/Loader';
import { publicApi } from '../util';

const options = {
  theme: 'light',
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const FindAccount = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [emailOrPhone, setEmailOrPhone] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBackClick = () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (emailOrPhone === '') {
      toast.error('Please enter your email or phone number', options);
      return;
    }
    localStorage.setItem('resetEmailOrPhone', emailOrPhone);
    setLoading(true);
    try {
      const response = await publicApi.post('/find-account', { emailOrPhone });
      setLoading(false);
      toast.success(response.data.message, options);
      navigate('/verify-otp');
    } catch (error) {
      setLoading(false);
      if (error.response.status === 404) {
        toast.error('Account not found', options);
      } else {
        toast.error('Check your internet and please try again', options);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    setEmailOrPhone(event.target.value);
  };

  return (
    <motion.section
      className={styles.container}
      initial={{ opacity: 0, x: '-100%' }}
      whileInView={{ opacity: 1, x: 0 }}
    >
      <MdKeyboardBackspace className={styles.angle} onClick={handleBackClick} />
      <article className={styles.title}>
        <h1 className={styles.subtitle}>Reset your password</h1>
        <p className={styles.desc}>
          Enter your email address or phone number to regain access to your account
        </p>
        <form className={styles.form} onSubmit={handleSubmit}>
          <input
            type="text"
            id="emailOrPhone"
            name="emailOrPhone"
            className={styles.input}
            placeholder="Email or Phone Number"
            value={emailOrPhone}
            onChange={handleInputChange}
          />
          <button type="submit" className={styles.btn} disabled={loading}>
            Find my Account
          </button>
        </form>
      </article>
      {loading && <Loader loading={loading} />}
    </motion.section>
  );
};

export default FindAccount;
