/* eslint-disable no-unreachable */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdKeyboardBackspace } from 'react-icons/md';
import 'aos/dist/aos.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion } from 'framer-motion';
import styles from './styles/FindAccount.module.scss';
import Loader from '../PalmSchool/components/Loader';
import { publicApi } from '../util';

const options = {
  theme: 'light',
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const phone = localStorage.getItem('resetEmailOrPhone');
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });

  const handleBackClick = () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      toast.error('Password does not match', options);
      return;
    }
    setLoading(true);
    try {
      const response = await publicApi.post('/reset-password', { phone, newPassword: formData.password });
      setLoading(false);
      toast.success(response.data.message, options);
      navigate('/login');
    } catch (error) {
      setLoading(false);
      if (error.response.status === 404) {
        toast.error('Account not found', options);
      } else {
        toast.error('Check your internet and please try again', options);
      }
    }
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <motion.section
      className={styles.container}
      initial={{ opacity: 0, x: '-100%' }}
      whileInView={{ opacity: 1, x: 0 }}
    >
      <MdKeyboardBackspace className={styles.angle} onClick={handleBackClick} />
      <article className={styles.title}>
        <h1 className={styles.subtitle}>Set New Password</h1>
        <form className={styles.form} onSubmit={handleSubmit}>
          <input
            type="password"
            id="password"
            name="password"
            className={styles.input}
            placeholder="Enter a new password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            className={styles.input}
            placeholder="Repeat your new password"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            required
          />
          <button type="submit" className={styles.btn} disabled={loading}>
            Reset Password
          </button>
        </form>
      </article>
      {loading && <Loader loading={loading} />}
    </motion.section>
  );
};

export default ResetPassword;
